import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link , useNavigate} from "react-router-dom";

import PropTypes from 'prop-types';
 
import { withTranslation } from "react-i18next";
import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';

import {post} from '../../helpers/api_helper'  

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import Spinners from "../../components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "../../components/Common/TableContainer";
 
import useDefaultPropsWarningSuppression from '../../useDefaultPropsWarningSuppression';

const PackageList = (props) => {
  useDefaultPropsWarningSuppression();

  //meta title
  document.title = props.t("Package List");

  const navigate = useNavigate();
  
  const [isNew, setIsNew] = useState(true)
  const [isLoading, setLoading] = useState(true)
  const [modal, setModal] = useState(false); 
  const [modalUpgrade, setModalUpgrade] = useState(false); 

  const [packages, setPackages] = useState();
  const [packageData, setPackageData] = useState();
  const [currentPackage, setCurrentPackage] = useState("");
  const [currentPackageId, setCurrentPackageId] = useState(0);
  const [upgrade_ids, setUpgrade_ids] = useState([]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setPackages(null);
    } else {
      setModal(true);
    }
  };
 
  const toggleUpgrade = () => {
    if (modalUpgrade) {
      setModalUpgrade(false);
      setPackages(null);
    } else {
      setModalUpgrade(true);
    }
  };
  
  const [total_reg, setTotal_reg] = useState(0);
  const [packageId, setPackageId] = useState(0);
  const [packageTitle, setPackageTitle] = useState("");

  const handlePackageClick = useCallback((arg) => {  
    setPackageId(arg.id); 
    setPackageTitle("$"+arg.title);  
    setModal(true);
  }, [toggle]);

  const handlePackageUpgradeClick = useCallback((arg, ids) => {   
    setUpgrade_ids(arg.upgrade_ids); 
    setCurrentPackageId(arg.id); 
    setCurrentPackage("$"+arg.title);  
    setModalUpgrade(true);
  }, [toggle]);

  const handlePurchasePackage = async () => {
    try {  
        const obj = JSON.parse(localStorage.getItem("authUser")); 
        let values = {
          id: packageId,
          token: obj.token,
        };  
        const response = await post("/trade/purchasePackage", values); 

        if (response.data.code === 3002) {
            navigate('/logout'); 
        } 

        if (response.data.code !== 1) { 
          if(response.data.data?.message){
            let message = parseMessage(props.t(response.data.data.message), response.data.data.params);
            Notify.failure(message); 
          } else { 
            Notify.failure(props.t(response.data.message)); 
          } 
        } else { 
          
          if(response.data.data?.result){
            Notify.success(props.t(response.data.data.result)); 
          } else { 
            Notify.success(props.t(response.data.message)); 
          } 

          setModal(false);
          packageList(); 
        }
    } catch (error) {  
        console.log(error);
        // Notify.failure(error.data.message); 
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: { 
      newpackageid: "", 
    },
    validationSchema: Yup.object({  
      newpackageid: Yup.string().required(props.t("Please Select New Package"))
    }), 
    onSubmit: async (values) => { 
      try {  
          const obj = JSON.parse(localStorage.getItem("authUser"));  
          values.oldpackageid =  currentPackageId;
          values.token =  obj.token;
          const response = await post("/trade/upgradePackage", values); 

          if (response.data.code === 3002) {
              navigate('/logout'); 
          } 

          if (response.data.code !== 1) { 
            if(response.data.data?.message){
              let message = parseMessage(props.t(response.data.data.message), response.data.data.params);
              Notify.failure(message); 
            } else { 
              Notify.failure(props.t(response.data.message)); 
            } 
          } else { 
            
            if(response.data.data?.result){
              Notify.success(props.t(response.data.data.result)); 
            } else { 
              Notify.success(props.t(response.data.message)); 
            } 

            setModalUpgrade(false);
            packageList(); 
          }
      } catch (error) {  
          console.log(error);
          // Notify.failure(error.data.message); 
      }
    }, 
  });

  const packageList = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const response = await post("/trade/packageList", {token: obj.token});

      if (response.data.code === 3002) {
        navigate('/logout'); 
    } 

      if (response.data.code !== 1) {
        Notify.failure(props.t(response.data.message)); 
      } else {
        if(response.data?.data){  
          let listdata = response.data.data.packages;  
          setPackageData(listdata); 
        } 
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => { 
    packageList(); // Call the async function immediately
  }, []); // Empty dependency array to run the effect only once
  

  useEffect(() => {
    const userProfile = async () => {
      try {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const response = await post("/user/profile", {token: obj.token});
  
        if (response.data.code === 3002) {
          navigate('/logout'); 
        } 

        if (response.data.code !== 1) {
          Notify.failure(props.t(response.data.message)); 
        } else {
          let userdata = response.data.data;
          setTotal_reg(userdata.total_reg);
     
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    userProfile(); // Call the async function immediately
  }, []); // Empty dependency array to run the effect only once

  const columns = useMemo(
    () => [ 
      {
        header: props.t("Package"),
        accessorKey: "amount",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              <h5 className="text-truncate font-size-14">
                <Link to={`#`} className="text-dark">$ {cellProps.row.original.amount} </Link>
              </h5> 
            </>
          );
        }
      },
      {
        header: props.t("direct reward"),
        accessorKey: "reward_referral_pal",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.reward_referral_pal}%
            </>
          );
        }
      }, 
  
      {
        header: props.t("community reward"),
        accessorKey: "reward_area_pal",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.reward_area_pal}%
            </>
          );
        }
      }, 
  
      {
        header: props.t("team level"),
        accessorKey: "reward_community_level",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.reward_community_level > 0 ? `1 - ${cellProps.row.original.reward_community_level}` : '-'}
            </>
          );
        }
      }, 
  
      {
        header: "#",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.can_buy === true ? (
                cellProps.row.original.is_new === true ? ( 
                      <button className="btn btn-primary btn-sm" onClick={() => handlePackageClick(cellProps.row.original)}>{props.t("purchase now")}</button> 
                ) : (
                  cellProps.row.original.can_upgrade === true ? ( 
                  <button className="btn btn-warning btn-sm" onClick={() => handlePackageUpgradeClick(cellProps.row.original)}>{props.t("Upgrade")}</button>
                  ):(
                    <button className="btn btn-success btn-sm" disabled>{props.t("already purchase")}</button>
                  )
                )
              ) : (
                <button className="btn btn-info btn-sm" disabled>{props.t("upcoming")}</button>
              )}
            </>
          );
        }
      },

      
    ],
    [props, isNew]
  );

  return (
    <React.Fragment> 
      <div className="page-content">
        <Container fluid>
          
          <Row>
            <Col lg="12">
              <div>
                {
                  isLoading ? <Spinners setLoading={setLoading} />
                    :
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody className="border-bottom">
                              <div className="d-flex align-items-center">
                                  <h5 className="mb-0 card-title flex-grow-1">{props.t("Package Lists")}</h5>
                                  <div className="flex-shrink-0">
                                      <Link to="/purchase-list"className="btn btn-primary me-1">{props.t("Purchase Records")}</Link> 
                                  </div>
                              </div>
                          </CardBody> 
                          <CardBody>
                            <TableContainer
                              columns={columns}
                              data={packageData || []}
                              isGlobalFilter={false}
                              isAddButton={false}
                              isPagination={false}
                              isCustomPageSize={false} 
                              SearchPlaceholder={props.t("9 records...")}
                              buttonClass="btn btn-success btn-rounded" 
                              tableClass="Package-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                              theadClass="table-light"
                              paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                              pagination="pagination"
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                }
              </div>
            </Col>
          </Row>
 
        </Container>
      </div>

      <Modal isOpen={modal} 
        centered
        toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
           {props.t("Purchase Package")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={12}> 
                <div className="mb-3">
                  <p>{props.t("Are you sure you want to purchase the package for ")}{" "}{packageTitle}{"?"}</p> 
                </div> 
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center">
                  <Button color="success" 
                    className="mr-1"
                    onClick={handlePurchasePackage}
                  >
                    {props.t("Confirm")}
                  </Button>
                  <Button color="danger"  
                    onClick={toggle}
                  >
                    {props.t("Cancel")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      

      <Modal isOpen={modalUpgrade} 
        centered
        toggle={toggleUpgrade}>
        <ModalHeader toggle={toggleUpgrade} tag="h4">
           {props.t("Upgrade")}
        </ModalHeader>
        <ModalBody>
          <Form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            > 
            <Row>
              <Col xs={12}>  
                <Row> 
                   <Col lg="12"> 
                     <FormGroup className="mb-3">
                       <label>{props.t("total_reg")}</label>
                       <InputGroup className="input-group-alternative mb-3 disabled">  
                         <Input 
                           value={total_reg}
                           disabled={true} 
                         />
                       </InputGroup>
                     </FormGroup> 
                   </Col>  
                 </Row>  
                <Row> 
                   <Col lg="12"> 
                     <FormGroup className="mb-3">
                       <label>{props.t("Current Package")}</label>
                       <InputGroup className="input-group-alternative mb-3 disabled">  
                         <Input 
                           value={currentPackage}
                           disabled={true} 
                         />
                       </InputGroup>
                     </FormGroup> 
                   </Col>  
                 </Row>  
                 <Row> 
                   <Col lg="12"> 
                     <FormGroup className="mb-3">
                         <label>{props.t("New Package")}</label>
                         <select
                           name="newpackageid"
                           className="form-select"
                           id="newpackageid-input"
                           onChange={validation.handleChange}
                           onBlur={validation.handleBlur}
                           value={validation.values.newpackageid} // Set the value attribute
                         >
                          <option value="">{props.t("Please Select New Package")}</option>
                          
                         {packageData?.length > 0 && packageData.map((packageItem) => {
                              if (Object.values(upgrade_ids).includes(packageItem.id)) {
                                  return (
                                      <option key={packageItem.id} value={packageItem.id}>
                                          ${packageItem.title}
                                      </option>
                                  );
                              }
                              return null;
                          })}
                         </select> 
                        {validation.touched.newpackageid && validation.errors.newpackageid ? (
                          <FormFeedback type="invalid">{validation.errors.newpackageid}</FormFeedback>
                        ) : null}
                     </FormGroup>  
                   </Col>  
                 </Row>  
              </Col>
            </Row>
              
            <div className="row justify-content-center"> 
              <Col className="row justify-content-center"> 
                  <button
                    className="btn btn-primary btn-block "  
                    type="submit"
                    >
                    {props.t("Submit")}
                  </button> 
              </Col> 
            </div>  
          </Form>
        </ModalBody>
      </Modal>
      
      <ToastContainer />
    </React.Fragment>
  );
}; 
export default withTranslation()(PackageList);

PackageList.propTypes = {
  t: PropTypes.any
};