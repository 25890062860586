import React, { useState } from "react"
import { Row, Col, Card, CardBody, CardFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from "reactstrap" 
import {
    useLocation,
    useNavigate,
    useParams,
    Link
} from "react-router-dom";
import PropTypes from 'prop-types';
 
import { parseMessage, consoleLog } from '../../helpers/Utils'  
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { withTranslation } from "react-i18next";

const CardUser = (props) => {
  const { data } = props;
  let navigate = useNavigate();

  const [menu, setMenu] = useState(false)

  const goto = (path) => {
    navigate("/"+path)
  }
  return (
    <React.Fragment>
        <Row>
          <Col xl="3">
            <Card>
              <CardBody> 
                <div>
                  <div className="mb-4 me-3">
                    <i className="mdi mdi-account-circle text-primary h1"></i>
                  </div>

                  <div>
                    <h5>{data.username}</h5>
                    <p className="text-muted mb-1">{data.email}</p>
                    <p className="text-muted mb-0">{props.t("Invite Code")}: {data.inviteCode}</p>
                    <p className="text-muted mb-0">{props.t("KYC Status")}: <span className={data.kyc_code === 1 ? 'text-success' : 'text-danger'}>{props.t(data.kyc_status)}</span></p>

                  </div>
                </div>
              </CardBody> 
            </Card>
          </Col>
          <Col xl="9">
            <div className="wallet-box">
                <Row>
                  <Col sm="6" md="3"> 
                  <Card>
                    <CardBody> 
                      <div>  
                          <h5>{props.t("total_reg")}</h5>
                          <h1>{data.total_reg}</h1>  
                      </div>
                    </CardBody>
                    
                    <CardFooter className="bg-transparent border-top">
                      <div className="text-center">
                        <span onClick={() => goto("deposit")}  className="btn btn-light btn-sm">
                        {props.t("Deposit")}
                        </span>{" "}
                        <span onClick={() => goto('transfer')} className="btn btn-primary btn-sm">
                        {props.t("Transfer")}
                        </span>
                      </div>
                    </CardFooter> 
                    </Card>
                  </Col>
                  <Col sm="6" md="3"> 
                  <Card>
                    <CardBody> 
                      <div>  
                          <h5>{props.t("total_cash")}</h5>
                          <h1>{data.total_cash}</h1>  
                      </div>
                    </CardBody>
                    
                    <CardFooter className="bg-transparent border-top">
                      <div className="text-center">
                        <span onClick={() => goto("withdrawal")}  className="btn btn-light  btn-sm">
                        {props.t("Withdrawal")}
                        </span>
                      </div>
                    </CardFooter> 
                    </Card>
                  </Col>
                  <Col sm="6" md="3"> 
                  <Card>
                    <CardBody> 
                      <div>  
                          <h5>{props.t("total_share")}</h5>
                          <h1>{data.total_share}</h1>  
                      </div>
                    </CardBody> 
                    
                    <CardFooter className="bg-transparent border-top">
                      <div className="text-center">
                        <span onClick={() => goto("market")}  className="btn btn-light  btn-sm">
                        {props.t("sell")}
                        </span>
                      </div>
                    </CardFooter> 
                  </Card>
                  </Col>
                  <Col sm="6" md="3"> 
                  <Card>
                    <CardBody> 
                      <div>  
                          <h5>{props.t("total_rtgc")}</h5>
                          <h1>{data.total_rtgc}</h1>  
                      </div>
                    </CardBody> 
                  </Card>
                  </Col>
                </Row>
            </div>
          </Col>
        </Row>
    </React.Fragment>
  )
}
export default withTranslation()(CardUser);

CardUser.propTypes = {
  t: PropTypes.any
}; 
